























import { Component, Vue } from 'vue-property-decorator';
import * as confetti from 'canvas-confetti';

@Component({
  components: {

  },
})
export default class App extends Vue {
  private checklists: Checklist[] = [
    {
      name: "Emy",
      items: [
        {
          name: "Brush Teeth",
          done: false,
          icon: "tooth"
        },
        {
          name: "Brush Hair",
          done: false,
          icon: "brush",
          isSVG: true
        },
        {
          name: "Floride",
          done: false,
          icon: "tooth"
        },
        {
          name: "Pajamas",
          done: false,
          icon: "tshirt"
        },
        {
          name: "Prayers",
          done: false,
          icon: "pray"
        }
      ]
    },
    {
      name: "Hazel",
      items: [
        {
          name: "Brush Teeth",
          done: false,
          icon: "tooth"
        },
        {
          name: "Brush Hair",
          done: false,
          icon: "brush",
          isSVG: true
        },
        {
          name: "Pajamas",
          done: false,
          icon: "tshirt"
        },
        {
          name: "Everest",
          done: false,
          icon: "mountain"
        },
        {
          name: "Prayers",
          done: false,
          icon: "pray"
        }
      ]
    }
  ];

  private svgs: {[key:string]: string} = {
    'brush': '/svgs/comb.svg'
  };

  private completeATask() {
    confetti.default()
  }
}

interface Checklist {
  name: string;
  items: ToDoItem[];
}

interface ToDoItem {
  name: string;
  done: boolean;
  icon: string;
  isSVG?: boolean;
}
